import React from "react";

import { Paragraph1 } from "baseui/typography";
import { StyledLink } from "baseui/link";

import Page from "../components/internal-page";

export default function Covid() {
  return (
    <Page title="Информация о COVID-19">
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/1rYTyfpG7I9-nnVC77zxqjlW-Gsn5BdAa/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Информационная памятка
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/1gyGpWOQXafIXg9g2uGqp3FaLSCZ-ss_a/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Требования к посетителям МФО
        </StyledLink>
      </Paragraph1>
    </Page>
  );
}
